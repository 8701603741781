import {
  type ActionFunctionArgs,
  isRouteErrorResponse,
  json,
  redirect,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { type DeleteInviteData, type InviteUserData, isZodError } from 'api-client'
import { type ResendInvitationData } from 'api-client/organizations/model'
import { authorize } from 'keycloak'
import { type typeToFlattenedError, z } from 'zod'

import { DeleteInvitationForm } from '~/features/deleteInvitation'
import { InviteUserForm } from '~/features/inviteUser'
import { notify } from '~/features/notifications'
import { ResendInvitationForm } from '~/features/resendInvitation'

import {
  ActiveUsersList,
  DELETE_INVITE,
  DELETE_ORGANIZATION_USER,
  INVITE_USER,
  organizationsInvitedUsersQuery,
  organizationsUsersQuery,
  RESEND_INVITE,
} from '~/entities/organizations'
import { userOrganizationsQuery } from '~/entities/users'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
import { useDecodedToken } from '~/shared/model/auth/useAuth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { HeadTag } from '~/components/HeadTag'
import { PageHeader } from '~/components/PageHeader'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

export type ActionData = {
  fields?: InviteUserData & DeleteInviteData
  errors?: typeToFlattenedError<InviteUserData> & typeToFlattenedError<DeleteInviteData>
}

export function loader(queryClient: QueryClient) {
  return async () =>
    authorize(authStore, async () => {
      const { isLoading } = authStore.getState()

      const decodedToken = authStore.getState()?.decodedToken
      if (!decodedToken?.organization_id && !isLoading) return redirect('/')
      try {
        const [organizations, organizationUsers, organizationInvitedUsers] = await Promise.all([
          queryClient.ensureQueryData(userOrganizationsQuery()),
          decodedToken?.organization_id
            ? queryClient.ensureQueryData(organizationsUsersQuery(true))
            : undefined,
          decodedToken?.organization_id
            ? queryClient.ensureQueryData(organizationsInvitedUsersQuery(true))
            : undefined,
        ])

        return json({ organizationUsers, organizations, organizationInvitedUsers })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: `We had issues loading your organization information.`,
              description: `Please refresh the page and try again. If the problem persists, please contact support.`,
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export function action(queryClient: QueryClient) {
  return async ({ request }: ActionFunctionArgs) => {
    const data = await getFormData(request)
    const intents = z
      .enum([INVITE_USER, RESEND_INVITE, DELETE_INVITE, DELETE_ORGANIZATION_USER])
      .safeParse(data.intent)

    if (!intents.success) {
      throw json(
        {
          message: 'This operation is not valid',
          description:
            'You tried to perform an invalid operation. Nothing will be changed regarding your organization information. Please reload the page and try again.',
        },
        { status: 400 },
      )
    }

    switch (intents.data) {
      // invite user and add to projects
      case 'INVITE_USER': {
        console.log(data)
        const read_only_data = data?.read_only === 'on'

        const invite_data = { ...data, read_only: read_only_data }

        try {
          const { message } = await apiClient.organizations.sendInvite(invite_data)
          notify.success(message)

          await queryClient.invalidateQueries(organizationsInvitedUsersQuery(true))

          return json({ fields: { email: '', project_ids: [''] } })
        } catch (error) {
          if (isZodError<InviteUserData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }

      case 'DELETE_ORGANIZATION_USER': {
        try {
          const { message } = await apiClient.organizations.deleteUser(data)
          await queryClient.invalidateQueries(organizationsUsersQuery(true))
          notify.success(message)
          return redirect(`/organization`)
        } catch (error) {
          if (isZodError<DeleteInviteData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }

      case 'RESEND_INVITE': {
        try {
          const { message } = await apiClient.organizations.resendInvite(data)
          notify.success(message)

          await queryClient.invalidateQueries(organizationsInvitedUsersQuery(true))

          return json({})
        } catch (error) {
          if (isZodError<ResendInvitationData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }

      case 'DELETE_INVITE': {
        try {
          const { message } = await apiClient.organizations.deleteInvite(data)
          notify.success(message)

          await queryClient.invalidateQueries(organizationsInvitedUsersQuery(true))

          return json({})
        } catch (error) {
          if (isZodError<DeleteInviteData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
    }
  }
}

export default function OrganizationSettings() {
  const decodedToken = useDecodedToken()
  const { data: organization } = useQuery({
    ...userOrganizationsQuery(),
    select: data => data.find(org => parseInt(org.id) === decodedToken?.organization_id),
  })
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: invitedUsers } = useQuery(organizationsInvitedUsersQuery(isOrgAccount))

  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Organization settings - Gigapipe
      </HeadTag>

      <Box paddingX={{ mobile: 'xxsmall', tablet: 'large', desktop: 'xxlarge' }}>
        <Stack>
          <PageHeader title="Organization Settings" />

          <Card>
            <Card.Header title={`${organization?.name} Team`} />
            <Card.Section>
              <ActiveUsersList />
            </Card.Section>
            {invitedUsers?.length ? (
              <Card.Section>
                <Stack space="xsmall">
                  <Text size="medium" color="gray600">
                    Pending members
                  </Text>
                  {invitedUsers.map(invite => (
                    <Stack
                      direction="horizontal"
                      justify="between"
                      align="center"
                      key={invite.invitedUserEmail}
                    >
                      <Box>{invite.invitedUserEmail}</Box>
                      <Stack direction="horizontal" space="xsmall">
                        <ResendInvitationForm id={invite.id} />
                        <DeleteInvitationForm id={invite.id} />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Card.Section>
            ) : null}
          </Card>

          <Card>
            <Card.Header title="Invite new member" />
            <Card.Section>
              <InviteUserForm />
            </Card.Section>
          </Card>
        </Stack>
      </Box>
    </>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return <RouteErrorMessage message={error.data.message} description={error.data.description} />
  }

  throw error
}
