import { transformCamelObjectKeysToSnake } from 'plunger'
import { z } from 'zod'

import { type Rooquest } from '../../lib/rooquest'
import { type ProjectId } from '../../projects/model'
import { type MessageResponse } from '../../utils/types'

import {
  type HepicAddUsersData,
  hepicAddUsersSchema,
  hepicCloudInstanceSchema,
  hepicConfigSchema,
  hepicCreateTokenDataSchema,
  type HepicEnableData,
  hepicEnableSchema,
  hepicPricingPlanSchema,
  type HepicRemoveUsersData,
  hepicRemoveUsersSchema,
  type HepicTokenRequest,
  hepicTokenRequestSchema,
  type HepicUpdateUserRoleData,
  hepicUpdateUserRoleSchema,
  hepicUserSchema,
} from './model'

const hepicPricingPlans = z.array(hepicPricingPlanSchema)
const hepicCloudInstances = z.array(hepicCloudInstanceSchema)
const hepicUsers = z.array(hepicUserSchema)
const hepicTokens = z.array(hepicCreateTokenDataSchema)
const hepicStatistics = z.object({
  storage_usage_percentage: z.number().nullable().optional() || z.string().nullable().optional(), // old
  storage_usage_bytes: z.number().nullable().optional() || z.string().nullable().optional(), // old
  storage_quota: z.number().nullable().optional(), // new
  rotation_point_sec: z.number().nullable().optional(), // new
})
export default class HepicService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Add hepic integration to a project
   */
  public enableHepic = async (data: HepicEnableData) => {
    const { projectId, ...rest } = hepicEnableSchema.parse(data)
    const res = await this.rooquest.post('integrations/hepic/', {
      query: transformCamelObjectKeysToSnake({ projectId }),
      body: transformCamelObjectKeysToSnake(rest),
    })

    return hepicConfigSchema.parse(res)
  }

  /**
   * Get hepic available pricing plans
   */
  public getHepicPricingPlans = async () => {
    const res = await this.rooquest.get('integrations/hepic/plans')
    return hepicPricingPlans.parse(res)
  }

  /**
   * Get the available cloud instances to store hepic logs
   */
  public getHepicCloudInstances = async () => {
    // this is updated for db type
    const res = await this.rooquest.get('integrations/hepic/cloud/resources')

    return hepicCloudInstances.parse(res)
  }

  /**
   * Get hepic configuration
   */
  public getHepicConfig = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/hepic/config', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return hepicConfigSchema.parse(res)
  }

  /**
   * Add a user to HEPIC
   */
  public addHepicUsers = async (data: HepicAddUsersData) => {
    const { projectId, ...rest } = hepicAddUsersSchema.parse(data)
    const res = await this.rooquest.post('integrations/hepic/users', {
      query: transformCamelObjectKeysToSnake({ projectId }),
      body: {
        users: rest.users,
      },
    })

    return res
  }
  /**
   * Add a user to HEPIC
   */
  public removeHepicUsers = async (data: HepicRemoveUsersData) => {
    const { projectId, ...rest } = hepicRemoveUsersSchema.parse(data)
    const res = await this.rooquest.delete('integrations/hepic/users', {
      query: transformCamelObjectKeysToSnake({ projectId }),
      body: {
        users: rest.users,
      },
    })

    return res as MessageResponse
  }
  /**
   * Get users from HEPIC
   */
  public getHepicUsers = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/hepic/users', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return hepicUsers.parse(res)
  }

  /**
   * Update a user's role in HEPIC
   */
  public updateHepicUserRole = async (data: HepicUpdateUserRoleData) => {
    const { projectId, users } = hepicUpdateUserRoleSchema.parse(data)
    const res = await this.rooquest.patch('integrations/hepic/users/role', {
      query: transformCamelObjectKeysToSnake({ projectId }),
      body: {
        users: users,
      },
    })

    return res
  }
  /**
   * Enable/Disable `Hepic` data availabilty for `LogQL`
   */
  public toggleHepicMetric = async (projectId: ProjectId) => {
    const res = await this.rooquest.patch('integrations/hepic/metric', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  /**
   *
   * Enable/Disable `Hepic` Auto add users
   *
   */

  public toggleHepicAutoAddUsers = async (projectId: ProjectId) => {
    const res = await this.rooquest.post('integrations/hepic/auto-add', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  public getHepicStatistics = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/hepic/statistics', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return hepicStatistics.parse(res) || { storage_usage_percentage: 0, storage_usage_bytes: 0 }
  }

  public getHepicTokens = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/hepic/tokens', {
      query: transformCamelObjectKeysToSnake({ projectId, active: true }),
    })
    return hepicTokens.parse(res)
  }

  public addHepicToken = async (data: HepicTokenRequest) => {
    const { projectId } = data

    const tokenData = hepicTokenRequestSchema.parse(data)

    const { ttlDays, scope, name } = tokenData
    const res = (await this.rooquest.post('integrations/hepic/tokens', {
      body: transformCamelObjectKeysToSnake({ ttlDays, scope, name }),
      query: transformCamelObjectKeysToSnake({ projectId }),
    })) as MessageResponse
    if (res?.message) {
      return res
    } else {
      return { message: 'Token successfuly created' }
    }
  }
}
