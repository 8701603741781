import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { InfoIntegrationLink, IntegrationLink } from '~/entities/integrations/base'
import { qrynConfigQuery } from '~/entities/integrations/qryn'

import { cookies } from '~/shared/lib/useCookies'
import { useEnvironment } from '~/shared/lib/useEnvironment'
import { useDecodedToken } from '~/shared/model/auth/useAuth'

type QrynLinksProps = {
  projectId: Project['id']
}
export function QrynLinks({ projectId }: QrynLinksProps) {
  const decodedToken = useDecodedToken()
  const { isDev } = useEnvironment()
  const { data: config } = useQuery(qrynConfigQuery(projectId))

  const cookieName = decodedToken?.organization_id
    ? `${decodedToken?.organization_id}_${projectId}`
    : `personal_${projectId}`
  const urlTpl = import.meta.env.VITE_QRYN_VIEW_URL_TPL ?? 'https://view.qryn.{{.dev}}gigapipe.com'
  const viewLink =
    `${urlTpl}`.replaceAll('{{.dev}}', isDev ? 'dev.' : '') + `/#/search/#cookie=${cookieName}`

  useEffect(() => {
    const setupDomainName = import.meta.env.VITE_SETUP_DOMAIN_NAME ?? '.gigapipe.com'

    let auth_text = ``

    if (config) {
      if (config?.readOnlyToken && config?.readOnlyToken !== `` && config?.readOnlyToken !== null) {
        auth_text = `token:${config?.readOnlyToken}`
      } else {
        auth_text = `${config.apiKey}:${config.apiSecret}`
      }
      const cookieValue = JSON.stringify({
        auth: auth_text,
        url: config.url,
      })
      cookies.set(cookieName, cookieValue, { domain: setupDomainName, path: '/', secure: true })
    }
  }, [config, cookieName])

  return (
    <>
      <IntegrationLink
        textTransform="none"
        label="Docs"
        launchURL="https://gigapipe.com/docs.html"
      />

      <IntegrationLink textTransform="none" label="qryn View" launchURL={viewLink} />
      <InfoIntegrationLink label={`Your qryn Instance:`} url={config?.url} copyURL={config?.url} />
    </>
  )
}
