import { ProjectsMenu } from '~/features/selectProject'
import { UserMenu } from '~/features/userMenu'

import { Box } from '~/components/Box'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { Close, Gigapipe, Menu } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'

// remove user menu if VITE_HEPIC_DEMO

export type NavbarProps = {
  isSidebarOpen?: boolean
  toggleSidebar: () => void
}

export const Navbar = ({ isSidebarOpen, toggleSidebar }: NavbarProps) => (
  <Box backgroundColor="gigadarkblue" padding="small">
    <Stack direction="horizontal" justify="between" align="center">
      <Stack direction="horizontal" align="center" space="small">
        <Box display={{ mobile: 'block', desktop: 'none' }}>
          <Link to="/">
            <Icon size="xxlarge" color="gigapurple">
              <Gigapipe />
            </Icon>
          </Link>
        </Box>

        <ProjectsMenu />
      </Stack>

      <Stack direction="horizontal" justify="end" align="center" space="small">
        <Box display={{ mobile: 'none', desktop: 'block' }}>
          <UserMenu />
        </Box>
        <Box display={{ mobile: 'block', desktop: 'none' }}>
          <IconButton
            aria-label="open projects menu"
            icon={isSidebarOpen ? <Close /> : <Menu />}
            color="transparent"
            onPress={() => toggleSidebar()}
          />
        </Box>
      </Stack>
    </Stack>
  </Box>
)
