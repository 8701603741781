import { transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'

// Schemas for fetched requests
export const organizationSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    name: z.string(),
    readOnly: z.boolean().optional(),
    id: z.coerce.string(),
  }),
)

export const organizationInviteResponseSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    userExists: z.boolean(),
    userId: z.number(),
    organizationId: z.coerce.string(),
    error: z.optional(z.string()),
  }),
)

export const organizationDeleteUserSchema = z.object({
  id: z.coerce.number(), // user id
})

export type OrgDeleteUserData = z.infer<typeof organizationDeleteUserSchema>

export type OrganizationInviteResponse = z.infer<typeof organizationInviteResponseSchema>

export const orgUserSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.number(),
    givenName: z.string(),
    familyName: z.string(),
    email: z.string().email(),
  }),
)

export const dbOrgUserSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.number(),
    email: z.string().optional(),
  }),
)

export const invitedOrgUserSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.number(),
    invitingUserName: z.string(),
    invitedUserEmail: z.string(),
    createdAt: z.string(),
    expiresAt: z.string(),
    readOnly: z.boolean().optional(),
  }),
)

export const resendInviteUserSchema = z.object({
  id: z.coerce.number(),
})

export const trialDaysSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({ remainingDays: z.number() }),
)

export type Organization = z.infer<typeof organizationSchema>

export type InvitedUser = z.infer<typeof invitedOrgUserSchema>

// Schemas for submited requests
export const createOrganizationSchema = z.object({
  displayName: z
    .string()
    .trim()
    .min(3, { message: 'The organization name must have at least 3 characters' }),
  billingAccountId: z.string().trim().min(1, { message: 'The billing account ID is required' }),
})
export type CreateOrganizationData = z.infer<typeof createOrganizationSchema>

export const inviteUserSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required.' })
    .email('Please enter a valid email address.')
    .refine(val => (val.includes('+') ? val.includes('@gigapipe.com') : true), {
      message: 'The "+" sign is not allowed.',
    }),
  project_ids: z
    .preprocess(res => {
      if (Array.isArray(res)) {
        return res
      } else return [res]
    }, z.array(z.coerce.number()))
    .optional(),
  read_only: z.boolean().optional(),
})
export type InviteUserData = z.infer<typeof inviteUserSchema>
export type ResendInvitationData = z.infer<typeof resendInviteUserSchema>

export const deleteInviteSchema = z.object({
  inviteId: z.string().min(1, { message: 'You must select one invite to delete.' }),
})
export type DeleteInviteData = z.infer<typeof deleteInviteSchema>
export const acceptInvitationSchema = z.object({
  code: z.string(),
})
export type AcceptInvititationData = z.infer<typeof acceptInvitationSchema>
