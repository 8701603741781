import './env'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { faro } from '@grafana/faro-web-sdk'
import { posthog } from 'posthog-js'

import App from '~/app'

import { initializeFaro } from '~/shared/model/faro'

// eslint-disable-next-line import/no-unresolved
import 'unfonts.css'
// Enable mocking of network requests if we are running the app in dev mode `npm run dev` and MOCKING is enabled
if (import.meta.env.DEV && import.meta.env.VITE_ENABLE_API_MOCKING) {
  const { worker, customOnUnhandledRequest } = await import('../mocks')
  await worker.start({
    onUnhandledRequest: customOnUnhandledRequest,
  })
}

// Always initialize the Faro instance in a paused state. Unpause it just for production builds.
initializeFaro()
if (import.meta.env.PROD) {
  faro.unpause()
}

posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
  api_host: 'https://us.i.posthog.com',
  // Only capture events for the production environment
  autocapture: import.meta.env.VITE_ENVIRONMENT === 'production',
  capture_pageview: import.meta.env.VITE_ENVIRONMENT === 'production',
  capture_pageleave: import.meta.env.VITE_ENVIRONMENT === 'production',
  disable_session_recording: import.meta.env.VITE_ENVIRONMENT !== 'production',
  secure_cookie: true,
})

createRoot(document.getElementById('root')!).render(
  import.meta.env.VITE_DISABLE_STRICT_MODE ? (
    <App />
  ) : (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ),
)
